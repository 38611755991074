
::v-deep{
    .product-card{
        overflow: visible;
        position: relative;
        &__img{
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            transition: 0.25s ease-out;
        }
        &__contents{
            display: flex;
            flex-direction: column;
            align-items: center;
            background-image: url(/images/sub/products/product-card-bg.jpg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
        &::before,
        .v-ripple__container{
            opacity: 0 !important;
            display: none !important;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    ::v-deep{
        .product-card:hover{
            .product-card__img{
                transform: translateX(-50%) translateY(19%) scale(.7);
            }
        }
    }
}
@media (min-width:1200px){
}

